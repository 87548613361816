import React, { Component } from "react";
import firebase from "../firebase.js";
import Input from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import ReactExport from "react-export-excel";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import ReactToPrint from "react-to-print";
import "rc-datepicker/lib/style.css";
import fazanlogofull from "../fazanlogofull.png";
import {
  citaj2,
  divljacSifre,
  ulovLovci,
  funkcija,
  status,
  sekcija,
  revir,
} from "../funkcije.js";

var ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelRow = ReactExport.ExcelFile.ExcelRow;

class Izvjestaji extends Component {
  state = {
    filteri: "",
    rezultat: "",
    rezultatPrint: "",
    lovci: [],
    uplate: "",
    divljacSifre: [],
    uloviLovci: [],
    uloviLovciFilter: [],
    datumUplate: "",
    status: [],
    sekcija: [],
    revir: [],
    funkcija: [],
    kraj: "",
    pocetak: "",
    pretragaRS: "",
    odstrelS: "",
    pretragaC: "",
    pretragaS: "",
    excelInfo: [],
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  dajDanas = () => {
    let da = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
    this.setState({ kraj: da });
    let poc = new Date();
    poc.setFullYear(2020, 0, 1);
    // poc.setFullYear(2020);
    //poc.setDate("03");
    //poc.setMonth("03");

    poc = poc.toJSON().slice(0, 10).replace(/-/g, "-");
    this.setState({ pocetak: poc });
  };
  componentDidMount() {
    this.status();
    this.dajDanas();
  }

  glavni = {
    width: "100%",
    height: "100%",
    background: "#BEE9BF",
    position: "absolute",
    "text-align": "center",
    "vertical-align": "middle",
  };
  styles1 = {
    width: "975px",
    //height: "500px",
    "margin-top": "30px",
    "margin-left": "20px",
    background: "#BEE9BF",
    "vertical-align": "middle",
    //"text-align": "center",
    clear: "both",
  };

  stylesPrint = {
    width: "1100px",
    //height: "500px",
    "margin-top": "30px",
    "margin-left": "30px",
    "margin-bottom": "30px",
    //background: "#BEE9BF",
    "vertical-align": "middle",
    //"text-align": "center",
    clear: "both",
  };

  styles2 = {
    width: "200px",
    //height: "500px",
    "margin-top": "10px",
    "margin-left": "20px",
    background: "#BEE9BF",
    "vertical-align": "middle",
    "text-align": "center",
    float: "left",
  };

  styles22 = {
    width: "150px",
    //height: "500px",
    "margin-top": "10px",
    "margin-left": "10px",
    background: "#BEE9BF",
    "vertical-align": "middle",
    "text-align": "center",
    float: "left",
  };

  styles3 = {
    height: "37px",
  };

  ocisti = {
    clear: "both",
  };

  stylesB = {
    float: "left",
    "margin-top": "10px",
    "margin-left": "20px",
    "vertical-align": "middle",
    "text-align": "center",
  };
  stylesD = {
    float: "left",
    "margin-top": "20px",
    "margin-left": "25px",
  };

  stylesG = {
    border: "1px solid green",
    width: "1000px",
    background: "#BEE9BF",
    left: "50%",
    "margin-left": "-500px",
    "margin-top": "10px",
    position: "absolute",

    //left: "1%"
  };

  floatleft = {
    float: "left",
  };

  slika = {
    "margin-top": "100px",
    "margin-bottom": "10px",
    "margin-left": "100px",
    height: "180px",
    width: "180px",
  };

  dajFiltere = () => {
    this.setState({
      filteri: (
        <div>
          <div style={this.styles2}>
            <h6> REVIR I SEKCIJA</h6>
            <Input id="revir/sekcija" as="select" style={this.styles3}>
              <option value="sve">SVE</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="4">OZREN</option>
              <option value="4-1">Ozren-Boljanić</option>
              <option value="4-16">Ozren-Lipac</option>
              <option value="4-30">Ozren-Tekućica</option>
              <option value="4-23">Ozren-Pridjel</option>
              <option value="4-8">Ozren-RUJ Paklenica</option>
              <option value="4-28">Ozren-SOT Osojnica</option>
              <option value="4-3">Ozren-Brezici</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="1">KRNJIN</option>
              <option value="1-27">Krnjin-Stanovi</option>
              <option value="1-31">Krnjin-Velika Bukovica</option>
              <option value="1-13">Krnjin-Kladari</option>
              <option value="1-26">Krnjin-Kotorsko</option>
              <option value="1-17">Krnjin-Ljeskove Vode</option>
              <option value="1-11">Krnjin-Grabovica</option>
              <option value="1-24">Krnjin-Putnikovo Brdo</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="3">TREBAVA</option>
              <option value="3-14">Trebava-Kostajnica</option>
              <option value="3-4">Trebava-Bušletić</option>
              <option value="3-34">Trebava-Sjenina</option>
              <option value="3-6">Trebava-Donja Paležnica</option>
              <option value="3-7">Trebava-Gornja Paležnica</option>
              <option value="3-10">Trebava-Zelinja</option>
              <option value="3-15">Trebava-Kožuhe</option>
              <option value="3-19">Trebava-Osječani</option>
              <option value="3-20">Trebava-Osječanske Čivčije</option>
              <option value="3-36">Trebava-Skipovac</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="5">VUČIJAK</option>
              <option value="5-18">Vučijak-Majevac</option>
              <option value="5-22">Vučijak-Podnovlje</option>
              <option value="5-35">Vučijak-Ritešić</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="6-32">Lovac gost udruženja</option>
              <option value="7-33">Podmladak</option>
            </Input>
          </div>
          <div style={this.styles2}>
            <h6>VRSTA ULOVA</h6>
            <Input id="clanarineI" as="select" style={this.styles3}>
              <option value="sve">SVE</option>
              {this.state.divljacSifre.map((ss) => {
                return <option value={ss.naziv}>{ss.naziv}</option>;
              })}
            </Input>
          </div>

          <div style={this.styles2}>
            <h6>STATUS LOVCA:</h6>

            <Input id="statusI" as="select" style={this.styles3}>
              <option value="sve">SVE</option>
              {this.state.status.map((ss) => {
                return <option value={ss.sifra}>{ss.naziv}</option>;
              })}
            </Input>
          </div>

          <div style={this.styles2}>
            <h6>LOVNA GODINA:</h6>

            <Input id="lovnaGodinaI" as="select" style={this.styles3}>
              <option value="sve">SVE</option>
              <option value="2018/2019">2018/2019</option>
              <option value="2019/2020">2019/2020</option>
              <option value="2020/2021">2020/2021</option>
              <option value="2021/2022">2021/2022</option>
              <option value="2022/2023">2022/2023</option>
              <option value="2023/2024">2023/2024</option>
              <option value="2024/2025">2024/2025</option>
            </Input>
          </div>

          <div style={{ clear: "both" }}>
            <div style={this.stylesD}>
              <h6>OD:</h6>
            </div>
            <div style={this.styles22}>
              <DatePickerInput
                id="datumStart"
                defaultValue={this.state.pocetak}
                //locale="es"
                onChange={this.datumStart}
              />
            </div>
            <div style={this.stylesD}>
              <h6>DO:</h6>
            </div>
            <div style={this.styles22}>
              <DatePickerInput
                id="datumEnd"
                defaultValue={this.state.kraj}
                //locale="es"
                onChange={this.datumKraj}
              />
           
            </div>
          
          </div>
      <div style={this.styles2}>
        <Input id="odstrelStatus" as="select" style={this.styles3}>
              <option value="sve">SVE</option>
              <option value="aktivan">odobren</option>
              <option value="prijavljen">neodobren</option>
            </Input>
         </div>


          <div style={this.stylesB}>
            <Button
              className="btn btn-success centerButton"
              onClick={() => this.pretraga()}
            >
              Prikaži podatke
            </Button>
          </div>
        </div>
      ),
    });
  };

  datumStart = (date) => {
    let ddd =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ pocetak: ddd });
  };

  datumKraj = (date) => {
    let ddd =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ kraj: ddd });
  };

  dajUloveLovaca = () => {
    ulovLovci().then((snapshot) => {
      let ulovi = [];
      let ulovi2 = [];
      snapshot.forEach((lovac) => {
        var uidUlov = lovac.key;
        let ime = "";
        let prezime = "";
        let revir = "";
        let sekcija = "";
        let sifra = "";
        let funkcija = "";
        let status = "";
        let jmbg = "";
        this.state.lovci.forEach((ss) => {
          let uidLovac = ss.uid;

          if (uidUlov == uidLovac) {
            try {
              ime = ss.ime.toString();
              prezime = ss.prezime.toString();
              sifra = ss.sifra.toString();
              funkcija = ss.funkcija.toString();
              jmbg = ss.jmbg.toString();
              revir = ss.lovacRevirK.toString();
              sekcija = ss.lovacSekcijaK.toString();
              status = ss.lovacStatusK.toString();
            } catch (er) {
              alert(ss.key);
            }
          }
        });

        lovac.forEach((ulov) => {
          let brojLovneKarte = ulov.val().brojLovneKarte;
          let datumUlova = ulov.val().datumUlova;
          let lovnaGodina = ulov.val().lovnaGodina;
          let kolicinaUlova = ulov.val().kolicinaUlova;
          let lokacijaOpis = ulov.val().lokacijaOpis;
          let lokacijaRevir = ulov.val().lokacijaRevir;
          let lokacijaSekcija = ulov.val().lokacijaSekcija;
          let nalazUlova = ulov.val().nalazUlova;
          let polUlova = ulov.val().polUlova;
          let statusUlova = ulov.val().statusUlova;
          let tezinaUlova = ulov.val().tezinaUlova;
          let vrstaUlova = ulov.val().vrstaUlova;
          let kljucUlova = ulov.key;

          var upl = {
            uid: uidUlov,
            brojLovneKarte: brojLovneKarte,
            datumUlova: datumUlova,
            lovnaGodina: lovnaGodina,
            kolicinaUlova: kolicinaUlova,
            lokacijaOpis: lokacijaOpis,
            lokacijaRevir: lokacijaRevir,
            lokacijaSekcija: lokacijaSekcija,
            nalazUlova: nalazUlova,
            polUlova: polUlova,
            statusUlova: statusUlova,
            tezinaUlova: tezinaUlova,
            vrstaUlova: vrstaUlova,
            kljucUlova: kljucUlova,
            ime: ime,
            prezime: prezime,
            sifra: sifra,
            funkcija: funkcija,
            jmbg: jmbg,
            revir: revir,
            sekcija: sekcija,
            status: status,
          };
          let status2 = "";
          this.state.status.map((ss) => {
            if (ss.sifra == status) {
              status2 = ss.naziv;
            }
          });
          let revir2 = "";
          this.state.revir.map((ss) => {
            if (ss.sifra == revir) {
              revir2 = ss.naziv;
            }
          });
          let sekcija2 = "";
          this.state.sekcija.map((ss) => {
            if (ss.sifra == sekcija) {
              sekcija2 = ss.naziv;
            }
          });

          var upl2 = {
            uid: uidUlov,
            brojLovneKarte: brojLovneKarte,
            datumUlova: datumUlova,
            lovnaGodina: lovnaGodina,
            kolicinaUlova: kolicinaUlova,
            lokacijaOpis: lokacijaOpis,
            lokacijaRevir: lokacijaRevir,
            lokacijaSekcija: lokacijaSekcija,
            nalazUlova: nalazUlova,
            polUlova: polUlova,
            statusUlova: statusUlova,
            tezinaUlova: tezinaUlova,
            vrstaUlova: vrstaUlova,
            kljucUlova: kljucUlova,
            ime: ime,
            prezime: prezime,
            sifra: sifra,
            funkcija: funkcija,
            jmbg: jmbg,
            revir: revir2,
            sekcija: sekcija2,
            status: status2,
          };
          if (statusUlova == "aktivan" || statusUlova == "prijavljen") {
            ulovi.push(upl);
            ulovi2.push(upl2);
          }
        });
      });

      this.setState({ uloviLovci: ulovi });
      this.setState({ uloviLovciFilter: ulovi2 });

      //alert(
      //this.state.uplateLovci[0].sifra +
      // this.state.uplateLovci[0].vrstaUplate +
      // this.state.uplateLovci[0].ime +
      //this.state.uplateLovci[0].datumUplate
      // );
      this.dajFiltere();
      this.dajRezultat();
      //alert(this.state.uplateLovci[0].jmbg);
    });
  };

  dajLovce = () => {
    let bb = [];
    this.setState({ lovci: [] });

    citaj2().then((snapshot) => {
      snapshot.forEach((ss) => {
        let revir = "";
        this.state.revir.map((sss) => {
          if (sss.sifra == ss.val().lovacRevirK) {
            revir = sss.naziv;
            if (sss.sifra == "6" || sss.sifra == "7") {
              revir = "";
            }
          }
        });

        let sekcija = "";
        this.state.sekcija.map((sss) => {
          if (sss.sifra == ss.val().lovacSekcijaK) {
            sekcija = sss.naziv;
          }
        });

        let ime = ss.val().ime;
        let prezime = ss.val().prezime;
        let sifra = ss.val().sifra;
        let funkcija = ss.val().funkcija;
        let lozinka = ss.val().lozinka;
        let jmbg = ss.val().jmbg;
        let roditelj = ss.val().roditelj;
        let lovacRevirK = ss.val().lovacRevirK;
        let slika = ss.val().slika;
        let adresa = ss.val().adresa;
        let lovacZanimanjeK = ss.val().lovacZanimanjeK;
        let firma = ss.val().firma;
        let datumC = ss.val().datumC;
        let datumR = ss.val().datumR;
        let lovacInvaliditetK = ss.val().lovacInvaliditetK;
        let lovacNacijaK = ss.val().lovacNacijaK;
        let lovacSekcijaK = ss.val().lovacSekcijaK;
        let mjestoR = ss.val().mjestoR;
        let mjestoS = ss.val().mjestoS;
        let telefon = ss.val().telefon;
        let email = ss.val().email;
        let lovacSpremaK = ss.val().lovacSpremaK;
        let lovacStatusK = ss.val().lovacStatusK;
        let datumL = ss.val().datumL;
        let uid = ss.key;
        let androidStatus = ss.val().androidStatus;
        let androidDozvola = ss.val().androidDozvola;

        bb.push({
          ime: ime,
          prezime: prezime,
          sifra: sifra,
          funkcija: funkcija,
          lozinka: lozinka,
          roditelj: roditelj,
          jmbg: jmbg,
          lovacRevirK: lovacRevirK,
          slika: slika,
          lovacZanimanjeK: lovacZanimanjeK,
          firma: firma,
          datumC: datumC,
          datumR: datumR,
          lovacInvaliditetK: lovacInvaliditetK,
          lovacNacijaK: lovacNacijaK,
          lovacSekcijaK: lovacSekcijaK,
          adresa: adresa,
          mjestoR: mjestoR,
          mjestoS: mjestoS,
          telefon: telefon,
          email: email,
          lovacSpremaK: lovacSpremaK,
          lovacStatusK: lovacStatusK,
          uid: uid,
          revirEkran: revir,
          sekcijaEkran: sekcija,
          datumL: datumL,
          androidStatus: androidStatus,
          androidDozvola: androidDozvola,
        });
      });
      this.setState({ lovci: bb });

      //alert(this.state.baza[0].ime);
      //this.akcija(this.state.bazaP[0]);
      this.dajUloveLovaca();
    });
  };

  pretraga = () => {
    let datumS = new Date();
    datumS.setFullYear(this.state.pocetak.split("-")[0]);
    datumS.setMonth(this.state.pocetak.split("-")[1] - 1);
    datumS.setDate(this.state.pocetak.split("-")[2]);
    datumS.setHours(0, 0, 1, 1);

    let datumE = new Date();
    datumE.setFullYear(this.state.kraj.split("-")[0]);
    datumE.setMonth(this.state.kraj.split("-")[1] - 1);
    datumE.setDate(this.state.kraj.split("-")[2]);
    datumE.setHours(23, 59, 59, 999);

    let ulov = [];
    this.setState({ uloviLovciFilter: [] });

    let odstrelstatus = document.getElementById("odstrelStatus").options[
      document.getElementById("odstrelStatus").selectedIndex
    ].value;

    this.setState({ odstrelS: odstrelstatus });

    let izborVrsteUlova = document.getElementById("clanarineI").options[
      document.getElementById("clanarineI").selectedIndex
    ].value;

    this.setState({ pretragaC: izborVrsteUlova });

    let izborLovnaGodina = document.getElementById("lovnaGodinaI").options[
      document.getElementById("lovnaGodinaI").selectedIndex
    ].value;

    let izborStatus = document.getElementById("statusI").options[
      document.getElementById("statusI").selectedIndex
    ].value;

    //alert("Sa padalice " + izborStatus);

    let izborStatusNaziv = document.getElementById("statusI").options[
      document.getElementById("statusI").selectedIndex
    ].text;

    this.setState({ pretragaS: izborStatusNaziv });

    let izbor = document.getElementById("revir/sekcija").options[
      document.getElementById("revir/sekcija").selectedIndex
    ].value;

    let RevirSekcija = document.getElementById("revir/sekcija").options[
      document.getElementById("revir/sekcija").selectedIndex
    ].text;

    this.setState({ pretragaRS: izbor });

    let revirD;
    let sekcijaD;
    if (
      izbor == "sve" ||
      izbor == "4" ||
      izbor == "1" ||
      izbor == "5" ||
      izbor == "3"
    ) {
      revirD = izbor;
      sekcijaD = "nema";
    } else {
      revirD = document
        .getElementById("revir/sekcija")
        .options[
          document.getElementById("revir/sekcija").selectedIndex
        ].value.split("-")[0];
      sekcijaD = document
        .getElementById("revir/sekcija")
        .options[
          document.getElementById("revir/sekcija").selectedIndex
        ].value.split("-")[1];
    }

    this.setState({ excelInfo: [] });
    var excelInfoL = [];
    excelInfoL.push({
      revirSekcija: RevirSekcija,
      izborVrsteUlova: izborVrsteUlova,
      izborLovnaGodina: izborLovnaGodina,
      izborStatusNaziv: izborStatusNaziv,
      datumS: datumS,
      datumE: datumE,
      danas: this.state.kraj,
    });
    this.setState({ excelInfo: excelInfoL });

    this.state.uloviLovci.forEach((ss) => {
      try {
        let uid = ss.uid;
        let brojLovneKarte = ss.brojLovneKarte;
        let datumUlova = ss.datumUlova;
        let lovnaGodina = ss.lovnaGodina;
        let kolicinaUlova = ss.kolicinaUlova;
        let lokacijaOpis = ss.lokacijaOpis;
        let lokacijaRevir = ss.lokacijaRevir;
        let lokacijaSekcija = ss.lokacijaSekcija;
        let nalazUlova = ss.nalazUlova;
        let polUlova = ss.polUlova;
        let statusUlova = ss.statusUlova;
        let tezinaUlova = ss.tezinaUlova;
        let vrstaUlova = ss.vrstaUlova;
        let kljucUlova = ss.kljucUlova;
        let ime = ss.ime;
        let prezime = ss.prezime;
        let sifra = ss.sifra;
        let funkcija = ss.funkcija;
        let jmbg = ss.jmbg;
        let revir = ss.revir;
        let sekcija = ss.sekcija;
        let status = ss.status;

        if (
          revirD == "sve" ||
          (revir.toString() == revirD && sekcijaD == "nema") ||
          (revir.toString() == revirD && sekcija.toString() == sekcijaD)
        ) {
          if (
            izborVrsteUlova.toString() == "sve" ||
            izborVrsteUlova.toString() == vrstaUlova
          ) {



            if (
              odstrelstatus.toString() == "sve" ||
              odstrelstatus.toString() == statusUlova
            ) {



            if (
              izborLovnaGodina.toString() == "sve" ||
              izborLovnaGodina.toString() == lovnaGodina
            ) {
              if (
                izborStatus.toString() == "sve" ||
                izborStatus.toString() == status
              ) {
                let uplataDate = new Date();
                uplataDate.setFullYear(datumUlova.trim().split(".")[2]);
                uplataDate.setMonth(datumUlova.trim().split(".")[1] - 1);
                uplataDate.setDate(datumUlova.trim().split(".")[0]);

                //uplataDate.setFullYear(2020);
                //uplataDate.setMonth(3);
                //uplataDate.setDate(30);
                uplataDate.setHours(0, 0, 1, 1);

                if (datumS <= uplataDate && uplataDate <= datumE) {
                  this.state.status.map((ss) => {
                    if (ss.sifra == status) {
                      status = ss.naziv;
                    }
                  });

                  this.state.revir.map((ss) => {
                    if (ss.sifra == revir) {
                      revir = ss.naziv;
                    }
                  });

                  this.state.sekcija.map((ss) => {
                    if (ss.sifra == sekcija) {
                      sekcija = ss.naziv;
                    }
                  });
                  ulov.push({
                    uid: uid,
                    brojLovneKarte: brojLovneKarte,
                    datumUlova: datumUlova,
                    lovnaGodina: lovnaGodina,
                    kolicinaUlova: kolicinaUlova,
                    lokacijaOpis: lokacijaOpis,
                    lokacijaRevir: lokacijaRevir,
                    lokacijaSekcija: lokacijaSekcija,
                    nalazUlova: nalazUlova,
                    polUlova: polUlova,
                    statusUlova: statusUlova,
                    tezinaUlova: tezinaUlova,
                    vrstaUlova: vrstaUlova,
                    kljucUlova: kljucUlova,
                    ime: ime,
                    prezime: prezime,
                    sifra: sifra,
                    funkcija: funkcija,
                    jmbg: jmbg,
                    revir: revir,
                    sekcija: sekcija,
                    status: status,
                  });
                }
              }
            }
          }
          }
        }
      } catch (er) {
        alert(ss.key);
      }
    });

    this.setState({ uloviLovciFilter: ulov });
    this.setState({ rezultat: "" });
    let broj = 0;
    let brojP = 0;
    let brojUkupni = 0;
    ulov.map((ff) => {
      brojUkupni = brojUkupni + 1;
    });
    this.setState({
      rezultat: (
        <div>
          <div style={this.styles1}>
            <h4>Rezultati: {brojUkupni}</h4>

            {ulov.map((ss) => {
              broj = broj + 1;
              return (
                <h6>
                  {" "}
                  {broj}. {ss.ime} {ss.prezime} ({ss.sifra}) | {ss.revir} -
                  {ss.sekcija} | {ss.status} | {ss.vrstaUlova} | {ss.datumUlova}{" "}
                  
                </h6>
              );
            })}
          </div>
          <div className="d-none d-print-block" ref={(el) => (this.sss = el)}>
            <div style={this.stylesPrint}>
              <div>
                <div
                  style={{
                    float: "left",
                    "margin-top": "20px",
                    "margin-right": "170px",
                  }}
                >
                  <h5>Vrsta odstrela: {excelInfoL[0].izborVrsteUlova}</h5>
                  <h5>Revir/Sekcija: {excelInfoL[0].revirSekcija}</h5>
                  <h5>Lovna godina: {excelInfoL[0].izborLovnaGodina}</h5>
                  <h5>Status lovca: {excelInfoL[0].izborStatusNaziv}</h5>
                  <h5>
                    Period izvještaja od:{" "}
                    {excelInfoL[0].datumS.getDate().toString()}.
                    {excelInfoL[0].datumS.getMonth().toString()}.
                    {excelInfoL[0].datumS.getFullYear().toString()} do:{" "}
                    {excelInfoL[0].datumE.getDate().toString()}.
                    {excelInfoL[0].datumE.getMonth().toString()}.
                    {excelInfoL[0].datumE.getFullYear().toString()}
                  </h5>
                </div>
                <div style={{ float: "left", "margin-right": "10px" }}>
                  <img src={fazanlogofull} className="m-2" style={this.slika} />
                </div>
                <div>
                  <h6>Lovačko udruženje "Fazan" Doboj </h6>
                  <h6>Vidovdanska 26F, 74000 Doboj </h6>
                  <h6>Tel. 053 208 621, Fax 053 208 620 </h6>
                  <h6>web: www.fazandoboj.com </h6>
                  <h6>email: kancelarija@fazandoboj.com </h6>
                  <h6>JIB: 4400006740009 </h6>
                  <h6>PDV: 400006740009 </h6>
                </div>
              </div>
              <div style={{ height: "50px", clear: "both" }}></div>
              <div>
                {ulov.map((ss) => {
                  brojP = brojP + 1;
                  if (ss.revir == "Gosti udruženja") {
                    return (
                      <h6>
                        {" "}
                        {brojP}. {ss.ime} {ss.prezime} ({ss.jmbg}) ({ss.sifra}) | {ss.revir} -
                  {ss.sekcija} | {ss.status} | {ss.vrstaUlova} | {ss.datumUlova}{" "}
                      </h6>
                    );
                  } else {
                    return (
                      <h6>
                        {" "}
                        {brojP}. {ss.ime} {ss.prezime} ({ss.jmbg}) ({ss.sifra}) | {ss.revir} -
                  {ss.sekcija} | {ss.status} | {ss.vrstaUlova} | {ss.datumUlova}{" "}
                      </h6>
                    );
                  }
                })}
              </div>
              <div style={{ "margin-top": "30px" ,"margin-right": "30px", "text-align":"right" }}>
                <h5>
                  Datum: {this.state.kraj.split("-")[2]}.
                  {this.state.kraj.split("-")[1]}.
                  {this.state.kraj.split("-")[0]}
                </h5>
                
                  <h5 style={{ "margin-top": "30px"}}>Odgovorno lice _____________________</h5>
                  </div>
                
            </div>
          </div>
          <div>
            <ReactToPrint
              trigger={() => (
                <Button
                  size="sm"
                  className="m-2 btn btn-success centerButton"
                  href="#"
                >
                  Štampaj dokument
                </Button>
              )}
              pageStyle="size: auto;
                    margin: 20mm;"
              content={() => this.sss}
            />
          </div>
        </div>
      ),
    });
  };

  status = () => {
    status().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ status: ff });
      this.sekcija();
    });
  };

  sekcija = () => {
    sekcija().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;
        let revir = ss.val().revir;
        ff.push({
          sifra: sifra,
          naziv: naziv,
          revir: revir,
        });
      });

      this.setState({ sekcija: ff });
      this.revir();
    });
  };

  revir = () => {
    revir().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ revir: ff });
      this.funkcija();
    });
  };

  funkcija = () => {
    funkcija().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let sifra = ss.key;

        let naziv = ss.val().naziv;

        ff.push({
          sifra: sifra,
          naziv: naziv,
        });
      });

      this.setState({ funkcija: ff });
      this.dajSifreDivljac();
    });
  };

  dajSifreDivljac = () => {
    divljacSifre().then((snapshot) => {
      let ff = [];
      snapshot.forEach((ss) => {
        let naziv = ss.key;
        let klasa = ss.val().klasa;
        
        ff.push({
          naziv: naziv,
          klasa: klasa,
          
        });
      });

      this.setState({ divljacSifre: ff });
      this.dajLovce();
    });
  };

  dajRezultat = () => {
    let izborClanarina = document.getElementById("clanarineI").options[
      document.getElementById("clanarineI").selectedIndex
    ].value;

    this.setState({ rezultat: "" });
    let broj = 0;
    let brojUkupni = 0;
    this.state.uloviLovciFilter.map((ff) => {
      brojUkupni = brojUkupni + 1;
    });
    this.setState({
      rezultat: (
        <div>
          <div style={this.styles1}>
            <h4>Rezultati: {brojUkupni}</h4>

            {this.state.uloviLovciFilter.map((ss) => {
              broj = broj + 1;
              return (
                <h6>
                  {" "}
                  {broj}. {ss.ime} {ss.prezime} ({ss.sifra}) | {ss.revir} -
                  {ss.sekcija} | {ss.status} | {ss.vrstaUlova} | {ss.datumUlova}{" "}
                </h6>
              );
            })}
          </div>
        </div>
      ),
    });
  };

  render() {
    return (
      <div style={this.stylesG}>
        <div>{this.state.filteri}</div>
        <div style={this.styles3}>
          <ExcelFile
            filename={
              "Odstreli /" +
              this.state.pretragaC +
              "/" +
              this.state.pretragaS +
              "/" +
              this.state.kraj
            }
            element={
              <Button
                className="btn btn-primary centerButton"
                style={this.stylesB}
              >
                {" "}
                Snimi tabelu{" "}
              </Button>
            }
          >
            <ExcelSheet
              data={this.state.uloviLovciFilter}
              name={this.state.pretragaC + "-" + this.state.pretragaS}
            >
              <ExcelColumn label="Ime" value="ime" />
              <ExcelColumn label="Prezime" value="prezime" />
              <ExcelColumn label="Šifra" value="sifra" />
              <ExcelColumn label="JMBG" value="jmbg" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Revir lovca" value="revir" />
              <ExcelColumn label="Sekcija Lovca" value="sekcija" />
               <ExcelColumn label="Revir odstrela" value="lokacijaRevir" />
              <ExcelColumn label="Sekcija odstrela" value="lokacijaSekcija" />
              <ExcelColumn label="Vrsta odstrela" value="vrstaUlova" />
              <ExcelColumn label="Lovna godina" value="lovnaGodina" />
              <ExcelColumn label="Datum odstrela" value="datumUlova" />
              <ExcelColumn label="Količina odstrela" value="kolicinaUlova" />
              <ExcelColumn label="Težina odstrela" value="tezinaUlova" />
              <ExcelColumn label="Pol odstrela" value="polUlova" />
              <ExcelColumn label="Nalaz odstrela" value="nalazUlova" />
             
              <ExcelColumn label="Opis lokacije" value="lokacijaOpis" />
              <ExcelColumn label="Broj lovne karte" value="brojLovneKarte" />
              <ExcelColumn label="Status odstrela" value="statusUlova" />
                              
            </ExcelSheet>

            <ExcelSheet
              data={this.state.excelInfo}
              name="Informacije o pretrazi"
            >
              <ExcelColumn label="Revir/Sekcija" value="revirSekcija" />
              <ExcelColumn label="Vrsta odstrela" value="izborVrsteUlova" />
              <ExcelColumn label="Lovna Godina" value="izborLovnaGodina" />
              <ExcelColumn label="Status lovca" value="izborStatusNaziv" />
              <ExcelColumn label="Datum od" value="datumS" />
              <ExcelColumn label="Datum do" value="datumE" />
              <ExcelColumn label="Datum izvještaja" value="danas" />
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div>{this.state.rezultat}</div>
      </div>
    );
  }
}

export default Izvjestaji;
